import { useEffect } from 'react';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useSplitTreatments } from '@splitsoftware/splitio-react';
import { getEnv } from '~/utils/env';

const { DATADOG_APPLICATION_ID, DATADOG_CLIENT_TOKEN, BUILD_VERSION, ENV } =
  getEnv();

const SPLIT_TREATMENTS = [
  'Datadog_Browser_Logs_Session_Sample_Rate',
  'Datadog_RUM_Session_Replay_Sample_Rate',
  'Datadog_RUM_Session_Sample_Rate',
];

function initialize(
  sessionSampleRate,
  rumSessionSampleRate,
  rumSessionReplaySampleRate
) {
  if (DATADOG_APPLICATION_ID && DATADOG_CLIENT_TOKEN) {
    datadogLogs.init({
      clientToken: DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'sales-history-ui',
      env: ENV,
      forwardConsoleLogs: 'all',
      forwardErrorsToLogs: true,
      sessionSampleRate,
    });

    datadogRum.init({
      applicationId: DATADOG_APPLICATION_ID,
      clientToken: DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'sales-history-ui',
      env: ENV,
      version: BUILD_VERSION,
      sessionSampleRate: rumSessionSampleRate,
      sessionReplaySampleRate: rumSessionReplaySampleRate,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
    });

    datadogRum.startSessionReplayRecording();
  }
}

export const DatadogProvider = ({ children }) => {
  const { treatments, isReady } = useSplitTreatments({
    names: SPLIT_TREATMENTS,
  });

  useEffect(() => {
    if (isReady) {
      const {
        Datadog_Browser_Logs_Session_Sample_Rate: sessionSampleRate,
        Datadog_RUM_Session_Sample_Rate: rumSessionSampleRate,
        Datadog_RUM_Session_Replay_Sample_Rate: rumSessionReplaySampleRate,
      } = treatments;

      initialize(
        Number(sessionSampleRate.treatment) || 0,
        Number(rumSessionSampleRate.treatment) || 0,
        Number(rumSessionReplaySampleRate.treatment) || 0
      );
    }
  }, [isReady, treatments]);

  return children;
};
