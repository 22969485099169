import React from 'react';

import { ChakraProvider } from '@chakra-ui/react';
import {
  Fonts,
  fanaticsCollectAppTheme,
} from '@fanatics-live/common-components';
import PropTypes from 'prop-types';

function Theme({ children }) {
  return (
    <ChakraProvider theme={fanaticsCollectAppTheme}>
      <Fonts />
      {children}
    </ChakraProvider>
  );
}

Theme.propTypes = {
  children: PropTypes.any,
};

export default Theme;
