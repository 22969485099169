import React from 'react';

import 'focus-visible/dist/focus-visible';
import { ensureReady } from '@jaredpalmer/after';
import { hydrate } from 'react-dom';
import TagManager from 'react-gtm-module';
import { BrowserRouter } from 'react-router-dom';

import { AppProvider } from './components/AppProvider';
import routes from './features/nav/routes';
import { getEnv } from './utils/env';
import './utils/wdyr';
import './ssr/client.css';

const { GTM_ID } = getEnv();

if (GTM_ID) {
  TagManager.initialize({
    gtmId: GTM_ID,
  });
}

ensureReady(routes).then((data) =>
  hydrate(
    <BrowserRouter>
      <AppProvider data={data} />
    </BrowserRouter>,
    document.getElementById('root')
  )
);

if (module.hot) {
  module.hot.accept();
}
