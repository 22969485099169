import React from 'react';

import { Box } from '@chakra-ui/react';
import { Loader, Navigation, useAuth } from '@fanatics-live/common-components';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { withRouter } from 'react-router';
import { getEnv } from '~/utils/env';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function App({ children, isInitializing }) {
  const auth = useAuth();

  return (
    <>
      <Helmet>
        <title>Fanatics Collect Sales History</title>
        <meta
          content="Each trading card sold through Fanatics Collect is available for public viewing with the exact image, description, grade, date, and sales price."
          name="description"
        />
        <style type="text/css">{`
          /* Nav profile menu height fix */
          .chakra-portal .chakra-slide[role="navigation"] {
            bottom: auto !important;
          }
        `}</style>
      </Helmet>
      <Navigation
        showNonProdBanner
        showPastSearches
        auth={auth}
        fanIdBaseUrl={getEnv('FAN_ID_BASE_URL')}
        fanIdClientId={getEnv('FAN_ID_CLIENT_ID')}
        fanIdPreview={getEnv('FAN_ID_PREVIEW')}
        fanaticsCollectBaseUrl={getEnv('MONOLITH_URL')}
        isInitializing={isInitializing}
        pastSearchBaseUrl={getEnv('PAST_SEARCH_URL')}
        searchBaseUrl={getEnv('SEARCH_URL')}
      >
        {isInitializing ? (
          <Box height="250px">
            <Loader mt="20px" />
          </Box>
        ) : (
          children
        )}
      </Navigation>
    </>
  );
}

App.propTypes = {
  children: PropTypes.any,
  isInitializing: PropTypes.bool,
};

export default withRouter(App);
