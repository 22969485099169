import { isClient } from './isClient';

export const getEnv = (key = null) => {
  const env = isClient() ? window.env : process.env;

  const envData = {
    REACT_APP_API_URL: env.REACT_APP_API_URL,
    MONOLITH_URL: env.MONOLITH_URL,
    AUTH_URL: env.AUTH_URL,
    SEARCH_URL: env.SEARCH_URL,
    PAST_SEARCH_URL: env.PAST_SEARCH_URL,
    MOBILE_URL: env.MOBILE_URL,
    AUCTIONS_URL: env.AUCTIONS_URL,
    MEMBERS_API_URL: env.MEMBERS_API_URL,
    GRAPHQL_API_URL: env.GRAPHQL_API_URL,
    CHECKOUT_API_URL: env.CHECKOUT_API_URL,
    FAN_ID_BASE_URL: env.FAN_ID_BASE_URL,
    FAN_ID_CLIENT_ID: env.FAN_ID_CLIENT_ID,
    FAN_ID_PREVIEW: env.FAN_ID_PREVIEW,
    GTM_ID: env.GTM_ID,
    NODE_ENV: env.NODE_ENV,
    ENV: env.ENV,
    BUILD_VERSION: env.BUILD_VERSION,
    DATADOG_APPLICATION_ID: env.DATADOG_APPLICATION_ID,
    DATADOG_CLIENT_TOKEN: env.DATADOG_CLIENT_TOKEN,
    MARKETPLACE_PUBLISHABLE_KEY: env.MARKETPLACE_PUBLISHABLE_KEY,
    SPLIT_CLIENT_KEY: env.SPLIT_CLIENT_KEY,
  };

  return key ? envData[key] : envData;
};
