import React from 'react';

import { asyncComponent } from '@jaredpalmer/after';
import { NotFoundPage } from '~/pages/NotFoundPage';

const RouteNames = {
  INDEX: '/',
  ITEM: '/items/',
};

export default [
  {
    path: RouteNames.INDEX,
    exact: true,
    component: asyncComponent({
      loader: () => import('../../pages/Research'),
    }),
  },
  {
    path: `${RouteNames.ITEM}:id`,
    exact: true,
    component: asyncComponent({
      loader: () => import('../../pages/ResearchItem'),
    }),
  },
  {
    component: () => <NotFoundPage />,
  },
];
