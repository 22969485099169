import React from 'react';

import {
  NotFoundPage as CommonNotFoundPage,
  useAuth,
} from '@fanatics-live/common-components';
import { getEnv } from '~/utils/env';

const { MONOLITH_URL, AUCTIONS_URL } = getEnv();

export const NotFoundPage = () => {
  const auth = useAuth();

  return (
    <CommonNotFoundPage
      auctionsBaseUrl={AUCTIONS_URL}
      fanaticsMarketplaceBaseUrl={MONOLITH_URL}
      showEvents={!!auth?.profile}
    />
  );
};
